import Typography from 'typography'
import Noriega from 'typography-theme-noriega'
import colors from '../colors'

Noriega.overrideThemeStyles = () => ({
  'a.gatsby-resp-image-link': {
    boxShadow: 'none',
  },
  h1: {
    color: colors.title,
    fontWeight: 400,
  },
  'a, a code.language-text': {
    color: colors.accent,
  },
  h2: {
    color: colors.title,
    marginBottom: '15px',
    marginTop: '20px',
  },
  blockquote: {
    paddingLeft: '5px',
    fontStyle: 'italic',
    borderLeft: `4px solid ${colors.accent}`,
    background: 'rgba(0,0,0,0.02)'
  },
  'h3 code.language-text': {
    fontSize: '21px',
  },
  h3: {
    marginBottom: '8px',
  },
  p: {
    marginBottom: '1.3rem',
  },
  li: {
    marginBottom: '4px',
  },
})

Noriega.baseFontSize = '19px'
delete Noriega.googleFonts

const typography = new Typography(Noriega)

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
