import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

import { rhythm, scale } from '../utils/typography'
import colors from '../colors'

class Template extends React.Component {
  render() {
    const color = colors.accent
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <div
          style={{
            marginBottom: rhythm(1.5),
            display: 'inline-block',
            paddingLeft: '5px',
            paddingTop: '5px',
            paddingRight: '20px',
            borderBottom: `4px solid ${color}`,
            borderRight: `4px solid ${color}`,
            paddingBottom: '4px',
          }}
        >
          <h1
            style={{
              ...scale(1),
              marginTop: 0,
              marginBottom: 0,
              marginRight: 10,
              fontWeight: 400,
              lineHeight: '1.618rem',
              letterSpacing: '0.5px',
              color: '#444',
            }}
          >
            <Link
              style={{
                boxShadow: 'none',
                textDecoration: 'none',
                color: 'inherit',
              }}
              to={'/'}
            >
              Patrick El-Hage
            </Link>
          </h1>
          <p style={{ margin: '3px 0', color: '#9A9A9A', fontSize: '12px' }}>
            Software Engineer - Indie Hacker - Hip Hop Head
          </p>
        </div>
      )
    } else {
      header = (
        <div
          className="outer"
          style={{ marginTop: rhythm(-1.5), marginBottom: rhythm(1) }}
        >
          <span className="inner">
            <h3 style={{ margin: 0, color: colors.title, fontWeight: 400 }}>
              <Link
                style={{
                  boxShadow: 'none',
                  textDecoration: 'none',
                  color: 'inherit',
                }}
                to={'/'}
              >
                Patrick El-Hage
              </Link>
            </h3>
          </span>
        </div>
      )
    }
    return (
      <div
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <Helmet>
          {/*  //f1f3f5; */}
          <style type="text/css">{`
            body {
              background: ${colors.siteBg};
            }
            .outer {
              overflow: hidden;
              position: relative;

            }
            .outer:before {
              border-left: 4px solid ${color};
              border-right: 4px solid ${color};
              border-top: 4px solid ${color};
              content: " ";
              height: 50px;
              left: -16px;
              position: absolute;
              right: -16px;
              top: 50%;
            }
            .inner {
              position: relative;
              display: inline-block;
              padding: 16px;
              padding-left: 0;
              background: ${colors.siteBg};
            }
          `}</style>
        </Helmet>
        {header}
        {children}
      </div>
    )
  }
}

export default Template
