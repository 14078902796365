import React from 'react'
import { Link } from 'gatsby'
import get from 'lodash.get'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import colors from '../colors'
class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const posts = get(this, 'props.data.allMarkdownRemark.edges')

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          title={siteTitle}
          meta={[{ name: 'description', content: siteDescription }]}
        >
          <style type="text/css">{`
            .post {
              box-sizing: border-box;
              padding: 20px 16px;
              transition: background-color .25s ease;
            }
            .post h4 {
              color: ${colors.title};
              font-weight: 400;
              font-size: 20px;
            }
            .post:hover {
              box-shadow: -2px -2px 0 2px ${colors.accent};
            }
          `}</style>
        </Helmet>
        {posts.map(({ node }) => {
          const title = get(node, 'frontmatter.title') || node.fields.slug
          const externalLink = get(node, 'frontmatter.externalLink')
          const linkTo = externalLink ? externalLink : node.fields.slug
          const content = (
            <div className="post" key={linkTo}>
              <h4 style={{ marginBottom: '5px' }}>{title}</h4>
              <p
                style={{
                  margin: 0,
                  fontSize: '11px',
                  color: colors.subTitle,
                }}
              >
                {node.frontmatter.date}
              </p>
            </div>
          )
          const linkStyle = { textDecoration: 'none' }
          return externalLink ? (
            <a style={linkStyle} href={linkTo}>
              {content}
            </a>
          ) : (
            <Link style={linkStyle} to={linkTo}>
              {content}
            </Link>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            externalLink
          }
        }
      }
    }
  }
`
